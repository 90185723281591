import {
    IM_PLANT,
    IM_PLANT_SUCCESS,
    IM_PLANT_ERROR,
} from "../Types/plantIM.constant";
import { put, takeEvery } from "redux-saga/effects";

export function* setSelectedPlant(action: any): any {
    try {
        yield put({
            type: IM_PLANT_SUCCESS,
            payload: action.payload,
        });
    } catch (error) {
        yield put({
            type: IM_PLANT_ERROR,
            payload: null,
        });
    }
}

export function* injectionMoldingSaga() {
    yield takeEvery(IM_PLANT, setSelectedPlant);
}
