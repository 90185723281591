import { Directory } from "./SideNav-interfaces/sidenav.interface";

const MenuDirectory: Directory[] = [
    {
        title: "Usecase",
        icon: "Usecase",
        isSectionVisible: true,
        isOpen: false,
        children: [
            {
                name: "AGR Inspection System",
                level_use_code: "AGR",
                icon: "AGRInspectionSystem",
                route: "/usecase/inspection-system",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Air Leaks",
                level_use_code: "AILEMO",
                icon: "AirLeaks",
                route: "/usecase/air-leaks-monitoring",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Aseptic - Batch Room CCP",
                level_use_code: "Transmitters",
                icon: "AsepticBatchRoomCCP",
                route: "/usecase/aseptic-transmitters",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Aseptic - Process Room CCP",
                level_use_code: "CCP",
                icon: "AsepticProcessRoomCCP",
                route: "/usecase/aseptic-ccp",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Aseptic - Valves",
                level_use_code: "Valves",
                icon: "AsepticValves",
                route: "/usecase/aseptic-valves",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Aseptic CIP",
                level_use_code: "CIP",
                icon: "AsepticCIP",
                route: "/usecase/aseptic-cip",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Downtime Requests",
                level_use_code: "DowntimeRequests",
                icon: "DowntimeRequests",
                route: "/usecase/downtime-request",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Motors and Pumps PdM",
                level_use_code: "MOAP",
                icon: "MotorsAndPumps",
                route: "/usecase/motors-and-pumps",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Injection Analytics",
                level_use_code: "InjectionAnalytics",
                icon: "InjectionAnalytics",
                route: "/usecase/injection-analytics",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Injection Moulding",
                level_use_code: "InjectionMolding",
                icon: "InjectionMoulding",
                route: "/usecase/injection-moulding",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Removed LGV",
                level_use_code: "RemovedLGV",
                icon: "RemovedLGV",
                route: "/usecase/removed-lgv",
                isSelected: false,
                isVisible: false,
            },
            {
                name: "Run Hours PM",
                level_use_code: "RHBPV2",
                icon: "RunHours",
                route: "/usecase/run-hours-pm",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Servo Drives",
                level_use_code: "ServoDrives",
                icon: "ServoDrives",
                route: "/usecase/servo-drives",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Utilities",
                level_use_code: "UTIL",
                icon: "Utilities",
                route: "/usecase/utilities",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Warehouse System",
                level_use_code: "Warehouse",
                icon: "WarehouseSystem",
                route: "/usecase/warehouse-system",
                isSelected: false,
                isVisible: false
            },
            {
                name: "WQMS",
                level_use_code: "WQMS",
                icon: "WQMS",
                route: "/usecase/wqms",
                isSelected: false,
                isVisible: false
            },
            

        ]
    },
    {
        title: "LGV Health Monitor",
        icon: "LGVHealthMonitor",
        isSectionVisible: false,
        isOpen: false,
        children: [
            {
                name: "LGV Navigation Quality",
                level_use_code: "LgvNav",
                icon: "LGVNav",
                route: "/usecase/lgv-nav",
                isSelected: false,
                isVisible: false
            },
            {
                name: "LGV Hydraulic System Health",
                level_use_code: "LgvHsh",
                icon: "LGVHSH",
                route: "/usecase/lgv-hsh",
                isSelected: false,
                isVisible: false
            },
            {
                name: "LGV Inverter System Health",
                level_use_code: "LgvIsh",
                icon: "LGVISH",
                route: "/usecase/lgv-ish",
                isSelected: false,
                isVisible: false
            },
            {
                name: "LGV Battery System Health",
                level_use_code: "LgvBsh",
                icon: "LGVBattery",
                route: "/usecase/lgv-bsh",
                isSelected: false,
                isVisible: false
            }
        ]
    },
    {
        title: "EAM Prod Overlay",
        icon: "EAM",
        isSectionVisible: false,
        isOpen: false,
        children: [
            {
                name: "Scheduler",
                level_use_code: "EAM",
                icon: "EAM",
                route: "/eam-prod-overlay/scheduler",
                isSelected: false,
                isVisible: false
            }
        ]
    },
    {
        title: "SAT",
        icon: "SAT",
        isSectionVisible: false,
        isOpen: false,
        children: [
            {
                name: "SAT Summary",
                level_use_code: "NA",
                icon: "SAT",
                route: "/sat/sat-summary",
                isSelected: false,
                isVisible: false
            }
        ]
    },
    {
        title: "Locations",
        icon: "Location",
        isSectionVisible: false,
        isOpen: false,
        children: [
            {
                name: "Facilities",
                level_use_code: "NA",
                icon: "Facilities",
                route: "/locations/list",
                isSelected: false,
                isVisible: false
            }
        ]
    },
    {
        title: "Management",
        icon: "Management",
        isSectionVisible: false,
        isOpen: false,
        children: [
            {
                name: "Mapping",
                level_use_code: "NA",
                icon: "Mapping",
                route: "/asset-management/mapping",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Pool",
                level_use_code: "NA",
                icon: "Pool",
                route: "/asset-management/pool",
                isSelected: false,
                isVisible: false
            },
            {
                name: "Types",
                level_use_code: "NA",
                icon: "Types",
                route: "/asset-management/types",
                isSelected: false,
                isVisible: false
            }
        ]
    },
    {
        title: "Alerts",
        icon: "Alerts",
        isSectionVisible: false,
        isOpen: false,
        children: [
            {
                name: "Tickets",
                level_use_code: "NA",
                icon: "Tickets",
                route: "/maintenance/tickets",
                isSelected: false,
                isVisible: false
            }
        ]
    }
];

export { MenuDirectory }
