import { put, call, takeEvery, select } from "redux-saga/effects";
import { saveAs } from "file-saver";

import {
  LOAD_CIPWASH,
  LOAD_CIPWASH_SUCCESS,
  LOAD_CIPWASH_ERROR,
  GET_CIPWASH_BY_ID,
  GET_CIPWASH_BY_ID_SUCCESS,
  GET_CIPWASH_BY_ID_ERROR,
  LOAD_CIP_EXCEPTIONS,
  LOAD_CIP_EXCEPTIONS_SUCCESS,
  LOAD_CIP_EXCEPTIONS_ERROR,
  LOAD_CIP_EXCEPTIONS_EXPORT,
  LOAD_CIP_EXCEPTIONS_EXPORT_SUCCESS,
  LOAD_CIP_EXCEPTIONS_EXPORT_ERROR,
  CIPWASH_EXPORT,
  CIPWASH_EXPORT_SUCCESS,
  CIPWASH_EXPORT_ERROR,
} from "../Types/cipWash.constants";
import { LOAD_SNACKBAR } from "../Types/snackBar.constants";
import axios from "../../Utils/AxiosConfig/axiosConfig";

export const getWash = async (payload: any) => {
  try {
    let res = await axios.post(`washresults/getWashList`, {
      ...payload,
      page: payload.page + 1,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const fetchWashById = async (id: any) => {
  try {
    let res = await axios.get(`washresults/${id}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getCipExceptions = async (payload: any) => {
  try {
    let res = await axios.post(`messages/getCipMessages`, {
      ...payload,
      page: payload.page + 1,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const downloadExceptionsExcel = async (
  params: any,
  plantCode: string
) => {
  try {
    const res = await axios.post(
      `messages/exportCipMessages`,
      { ...params },
      { responseType: "arraybuffer" }
    );
    const blob = new Blob([res.data], {
      type: "application/octet-stream",
    });
    const buff = saveAs(blob, `NB ARCH CIP - ${plantCode} Exception List.xlsx`);
    return buff;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const downloadWashListCSV = async (payload: any) => {
  try {
    const res = await axios.post(`washresults/exportWashList`, payload, {
      responseType: "arraybuffer",
    });
    const blob = new Blob([res.data], {
      type: "application/octet-stream",
    });
    const buff = saveAs(blob, `NB ARCH CIP Wash List.xlsx`);
    return buff;
  } catch (error: any) {
    throw new Error(error);
  }
};

export function* listCIPWash(payload: any): any {
  try {
    const data = yield call(() =>
      getWash(payload.payload).then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_CIPWASH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: LOAD_CIPWASH_ERROR,
      payload: null,
    });
  }
}

export function* getWashById(payload: any): any {
  try {
    const data = yield call(() =>
      fetchWashById(payload.payload).then((res) => {
        return res;
      })
    );
    yield put({
      type: GET_CIPWASH_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: GET_CIPWASH_BY_ID_ERROR,
      payload: null,
    });
  }
}

export function* listCIPExceptions(payload: any): any {
  try {
    const data = yield call(() =>
      getCipExceptions(payload.payload).then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_CIP_EXCEPTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: LOAD_CIP_EXCEPTIONS_ERROR,
      payload: null,
    });
  }
}

export function* downloadCIPExceptions(payload: any): any {
  const { params, plantCode } = payload.payload;

  try {
    const data = yield call(() =>
      downloadExceptionsExcel(params, plantCode).then((res: any) => {
        return res;
      })
    );
    yield put({
      type: LOAD_CIP_EXCEPTIONS_EXPORT_SUCCESS,
      payload: data,
    });
    yield put({
      type: LOAD_SNACKBAR,
      payload: {
        message: "File generated successfully.",
        type: "success",
        open: true,
      },
    });
  } catch (error) {
    yield put({
      type: LOAD_CIP_EXCEPTIONS_EXPORT_ERROR,
      payload: null,
    });
    yield put({
      type: LOAD_SNACKBAR,
      payload: {
        message: "Simething went wrong. Please try again.",
        type: "error",
        open: true,
      },
    });
  }
}

export function* exportCIPWashes(): any {
  const { currentWashPayload: payload } = yield select(
    (state) => state.cipWashList
  );
  try {
    if (Object.keys(payload).length) {
      const { page, pageSize, ...query } = payload;

      yield call(() =>
        downloadWashListCSV(query).then((res: any) => {
          return res;
        })
      );

      yield put({
        type: CIPWASH_EXPORT_SUCCESS,
      });

      yield put({
        type: LOAD_SNACKBAR,
        payload: {
          message: "File exported successfully.",
          type: "success",
          open: true,
        },
      });
    } else {
      throw new Error("Payload cannot be empty");
    }
  } catch (error) {
    yield put({
      type: CIPWASH_EXPORT_ERROR,
    });
    yield put({
      type: LOAD_SNACKBAR,
      payload: {
        message: "Something went wrong while exporting, please try again.",
        type: "error",
        open: true,
      },
    });
  }
}

export function* listCIPWashSaga() {
  yield takeEvery(LOAD_CIPWASH, listCIPWash);
  yield takeEvery(GET_CIPWASH_BY_ID, getWashById);
  yield takeEvery(LOAD_CIP_EXCEPTIONS, listCIPExceptions);
  yield takeEvery(LOAD_CIP_EXCEPTIONS_EXPORT, downloadCIPExceptions);
  yield takeEvery(CIPWASH_EXPORT, exportCIPWashes);
}
