export const LOAD_CIPWASH = "LOAD_CIPWASH";
export const LOAD_CIPWASH_SUCCESS = "LOAD_CIPWASH_SUCCESS";
export const LOAD_CIPWASH_ERROR = "LOAD_CIPWASH_ERROR";
export const GET_CIPWASH_BY_ID = "GET_CIPWASH_BY_ID";
export const GET_CIPWASH_BY_ID_SUCCESS = "GET_CIPWASH_BY_ID_SUCCESS";
export const GET_CIPWASH_BY_ID_ERROR = "GET_CIPWASH_BY_ID_ERROR";
export const LOAD_CIP_EXCEPTIONS = "LOAD_CIP_EXCEPTIONS";
export const LOAD_CIP_EXCEPTIONS_SUCCESS = "LOAD_CIP_EXCEPTIONS_SUCCESS";
export const LOAD_CIP_EXCEPTIONS_ERROR = "LOAD_CIP_EXCEPTIONS_ERROR";
export const LOAD_CIP_EXCEPTIONS_EXPORT = "LOAD_CIP_EXCEPTIONS_EXPORT";
export const LOAD_CIP_EXCEPTIONS_EXPORT_SUCCESS =
  "LOAD_CIP_EXCEPTIONS_EXPORT_SUCCESS";
export const LOAD_CIP_EXCEPTIONS_EXPORT_ERROR =
  "LOAD_CIP_EXCEPTIONS_EXPORT_ERROR";
export const CIPWASH_EXPORT = "CIPWASH_EXPORT";
export const CIPWASH_EXPORT_SUCCESS = "CIPWASH_EXPORT_SUCCESS";
export const CIPWASH_EXPORT_ERROR = "CIPWASH_EXPORT_ERROR";
